import { createReducer, on } from '@ngrx/store';
import * as actions from './carrito.actions';
import { CarritoNQItem, CarritoQItem } from '../models';

export const carritoFeatureKey = 'carrito';

export interface State {
  q: CarritoQItem[];
  nq: CarritoNQItem[];
}

const initialState: State = {
  nq: [],
  q: [],
};

export const reducer = createReducer<State>(
  initialState,

  on(actions.AddTratamientoNoQuirurgicoToCarrito, (state, { item }) => {
    return {
      ...state,
      nq: [...state.nq, item],
    };
  }),

  on(actions.AddTratamientoQuirurgicoToCarrito, (state, { item }) => {
    const stateCopy = Object.assign({}, state);

    const existingItemIndex = stateCopy.q.findIndex(
      (t) => t.id_tratamiento === item.id_tratamiento,
    );

    if (existingItemIndex >= 0) {
      stateCopy.q = stateCopy.q.map((t) =>
        t.id_tratamiento === item.id_tratamiento
          ? { ...t, monto: t.monto + item.monto }
          : t,
      );
    } else {
      stateCopy.q = [...stateCopy.q, item];
    }

    return {
      ...state,
      q: stateCopy.q,
    };
  }),

  on(
    actions.RemoveTratamientoQuirurgicoFromCarrito,
    (state, { id_tratamiento }) => {
      const stateCopy = { ...state };
      stateCopy.q = stateCopy.q.filter(
        (t) => t.id_tratamiento !== id_tratamiento,
      );
      return {
        ...state,
        q: stateCopy.q,
      };
    },
  ),

  on(actions.RemoveTratamientoNoQuirurgicoFromCarrito, (state, { id_mes }) => {
    const stateCopy = { ...state };
    stateCopy.nq = stateCopy.nq.filter((t) => t.id_mes !== id_mes);
    return {
      ...state,
      nq: stateCopy.nq,
    };
  }),

  on(actions.CleanCarrito, () => initialState),
);
