import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ThemeService } from './core/services/theme/theme.service';
import { Nullable } from './shared/utils/types';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_LANG_KEY } from './shared/utils/constant';
import { IdiomasService } from './core/services/idiomas/idiomas.service';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  template: `<app-custom-loading></app-custom-loading>
    <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit {
  public title = '';
  public version: string = packageJson.version;
  public favIcon: Nullable<HTMLLinkElement> =
    document.querySelector('#favIcon') || null;
  constructor(
    private themeService: ThemeService,
    private titleService: Title,
    private translateService: TranslateService,
    private idiomasService: IdiomasService,
  ) {
    this.themeService.loadTheme();
    this.titleService.setTitle(environment.title);
    if (this.favIcon) {
      this.favIcon.href = `../assets/favicon/favicon.ico`;
    }
    this.loadLangs();
  }

  async ngOnInit(): Promise<void> {
    await loadMercadoPago();
  }

  loadLangs(): void {
    const storageLang = localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    this.translateService.addLangs(['es', 'en']);
    this.translateService.setDefaultLang('es');
    this.translateService.use(storageLang || this.translateService.defaultLang);
    this.idiomasService.getIdiomaPorDefecto().subscribe({
      next: (idioma) => {
        this.translateService.setDefaultLang(idioma || 'es');
      },
    });
  }
}
