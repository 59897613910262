import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store';
import * as authStore from 'src/app/auth/store';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  public isAuthenticated = false;
  public isPrimerInicio = false;

  constructor(private router: Router, private store: Store<AppState>) {
    this.store
      .select(authStore.selectIsAuthenticated)
      .subscribe((isAuthenticated) => (this.isAuthenticated = isAuthenticated));
    this.store
      .select(authStore.selectIsPrimerInicio)
      .subscribe((isPrimerInicio) => (this.isPrimerInicio = isPrimerInicio));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (this.isPrimerInicio && route.routeConfig?.path !== 'request-password') {
      return this.router.createUrlTree(['/auth/request-password']);
    }
    if (this.isAuthenticated) {
      return true;
    }
    return this.router.createUrlTree(['/auth/login']);
  }
}
