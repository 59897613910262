import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export const handledErrorStartsWithKey = 'errorHandled_';

@Pipe({
  name: 'controlErrorMessage',
})
export class ControlErrorMessagePipe implements PipeTransform {
  transform(controlError: ValidationErrors): string {
    const errorKeys: Record<string, string> = {
      required: 'CONTROL-ERROR-MESSAGES.REQUIRED',
      maxlength: 'CONTROL-ERROR-MESSAGES.MAXLENGTH',
      minlength: 'CONTROL-ERROR-MESSAGES.MINLENGTH',
      max: 'CONTROL-ERROR-MESSAGES.MAX',
      min: 'CONTROL-ERROR-MESSAGES.MIN',
      pattern: 'CONTROL-ERROR-MESSAGES.PATTERN',
      email: 'CONTROL-ERROR-MESSAGES.EMAIL',
      emailRegex: 'CONTROL-ERROR-MESSAGES.EMAIL-REGEX',
      emailIsAlreadyRegistered:
        'CONTROL-ERROR-MESSAGES.EMAIL-IS-ALREADY-REGISTERED',
      differentPassword: 'CONTROL-ERROR-MESSAGES.DIFFERENT-PASSWORD',
      alphabeticRegex: 'CONTROL-ERROR-MESSAGES.ALPHABETIC-REGEX',
      numberRegex: 'CONTROL-ERROR-MESSAGES.NUMERIC-REGEX',
      noSpecialCharacters: 'CONTROL-ERROR-MESSAGES.NO-SPECIAL-CHARACTERS',
    };

    let errorMessage =
      errorKeys[controlError.key] || 'CONTROL-ERROR-MESSAGES.INVALID-FIELD';

    if (controlError.key?.startsWith(handledErrorStartsWithKey)) {
      errorMessage = controlError.value;
    }

    return errorMessage;
  }
}
