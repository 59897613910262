import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastService } from '../services/toast/toast.service';
import { MESSAGE_ERROR } from '@shared/utils';
import { Logout } from 'src/app/auth/store';
import { ErrorHandled } from '../classes';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: Error | HttpErrorResponse): void {
    let toastMessage = '';
    const store = this.injector.get(Store);
    const toastService = this.injector.get(ToastService);
    toastMessage = error.message;
    if (error instanceof HttpErrorResponse) {
      const errorObj = error.error;
      toastMessage = error.message;
      const opts: Record<number, string> = {
        [HttpStatusCode.Unauthorized]:
          'Acceso caducado, vuelva a iniciar sesión.',
        [HttpStatusCode.Forbidden]:
          'No posee permisos para realizar esta acción.',
        [HttpStatusCode.NotFound]:
          'Error de comunicación con el servidor, contáctese con el administrador del sistema.',
        [HttpStatusCode.RequestTimeout]: 'Se excedió el tiempo de respuesta.',
        [HttpStatusCode.BadRequest]:
          'Los datos enviados son inválidos. Si cree haberlos enviado correctamente contáctese con el administrador del sistema.',
        [HttpStatusCode.UnprocessableEntity]:
          'Los datos enviados no cumplen con los requisitos esperados. Si cree haberlos enviado correctamente contáctese con el administrador del sistema.',
        [HttpStatusCode.TooManyRequests]: 'Ha realizado demasidas consultas.',
      };

      toastMessage = opts[error.status] || MESSAGE_ERROR;

      if (error.status === HttpStatusCode.Unauthorized)
        store.dispatch(Logout());

      if (errorObj.error_controlado || errorObj.error)
        toastMessage = errorObj.error_controlado || errorObj.error;
    } else if (error instanceof ErrorHandled) {
      toastMessage = error.message;
    } else {
      toastMessage = '';
    }
    if (toastMessage) {
      toastService.showError(toastMessage, 'error', {
        duration: 10000,
      });
    }
    // console.error(`[${GlobalErrorHandler.name}]`, error);
  }
}
