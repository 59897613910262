<div class="lang-selector" [ngClass]="classList">
  <mat-select
    class="w-full"
    [ngModel]="currentLang"
    (selectionChange)="setLang($event)"
    >
    <mat-option *ngFor="let lang of langOptions" [value]="lang">
      <!-- https://www.flaticon.com/packs/countrys-flags -->
      <img height="20" width="20" [src]="'../../../../assets/icon/'+ lang + '.png'" /> {{ lang | uppercase }}
    </mat-option>
  </mat-select>
</div>
