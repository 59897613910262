import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class CustomClipboardService {
  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {}

  copy(value: string): void {
    const copied = this.clipboard.copy(value);
    if (copied) {
      this.toastService.showInfo('¡Copiado!');
    } else {
      this.toastService.showError('¡Error al copiar!, vuelva a intentarlo.');
    }
  }
}
