import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as authStore from 'src/app/auth/store';
import { AppState } from '@store';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  public isAuthenticated = false;

  constructor(private router: Router, private store: Store<AppState>) {
    this.store
      .select(authStore.selectIsAuthenticated)
      .subscribe((isAuthenticated) => (this.isAuthenticated = isAuthenticated));
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isAuthenticated) {
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
