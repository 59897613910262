import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//MAtERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
//NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// REDUX
import { routerFeatureKey } from '@store/router';
import { reducers, metaReducers, APP_EFFECTS } from '@store';
import { environment } from '../environments/environment';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared';

import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { FullCalendarModule } from '@fullcalendar/angular';
registerLocaleData(localeEs, 'es');

export function translateHttpLoaderFactory(
  http: HttpClient,
): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const TRANSLATIONS_CONFIG = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateHttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
];

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const NGRX_IMPORTS = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictActionImmutability: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    stateKey: routerFeatureKey,
    routerState: RouterState.Minimal,
  }),
  EffectsModule.forRoot(APP_EFFECTS),
  StoreDevtoolsModule.instrument({
    name: 'Angular PBASE',
    logOnly: !environment.production,
  }),
];

const CUSTOM_MATERIAL_SETTINGS = [
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' },
  },
  { provide: MAT_DATE_LOCALE, useValue: 'es-VE' },
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false },
  },
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      ...new MatDialogConfig(),
      width: '90vw',
      maxWidth: '650px',
      maxHeight: '80vh',
    },
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    CoreModule,
    FullCalendarModule,
    ...NGRX_IMPORTS,
    ...TRANSLATIONS_CONFIG,
  ],
  providers: [...CUSTOM_MATERIAL_SETTINGS],
  bootstrap: [AppComponent],
})
export class AppModule {}
