const ROL_SU = 'SUPER ADMINISTRADOR';
const ROL_A = 'ADMINISTRADOR';

const MESSAGE_ERROR =
  'Ocurrió un error desconocido, contáctese con el administrador del sistema';
const PEMISSIONS_ERROR = 'No posee los permisos suficientes para acceder';

const INTERNAL_SERVER_ERROR = 500;
const NOT_FOUND_ERROR = 404;
const UNAUTHORIZED = 401;
const UNPROCESSABLE_ENTITY = 422;
const FORBIDDEN = 403;
const UNKNOWN_ERROR = 0;
const METHOD_NOT_ALLOWED = 405;

const KEY_ENCRYPT = 'TecSolutions2023Escobar25008343';

const LOCAL_STORAGE_LANG_KEY = 'lang';

export {
  MESSAGE_ERROR,
  PEMISSIONS_ERROR,
  UNPROCESSABLE_ENTITY,
  INTERNAL_SERVER_ERROR,
  NOT_FOUND_ERROR,
  UNAUTHORIZED,
  FORBIDDEN,
  UNKNOWN_ERROR,
  METHOD_NOT_ALLOWED,
  ROL_SU,
  ROL_A,
  KEY_ENCRYPT,
  LOCAL_STORAGE_LANG_KEY,
};
