import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appExtended]',
})
export class ExtendedDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'border-radius',
      '24px',
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'padding-left',
      '20px',
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'padding-right',
      '20px',
    );
    this.renderer.setStyle(this.elementRef.nativeElement, 'width', 'auto');
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-width', '100%');
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', '48px');
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'line-height',
      'normal',
    );
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'flex');
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'align-items',
      'center',
    );
  }
}
