import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;
const baseUrl = environment.baseUrl;

export default {
  sendResetLinkEmail: `${baseUrl}/envio-email-cambio-contrasena`,
  newPassword: `${apiUrl}/first-login`,
  logout: `${apiUrl}/oauth/logout`,
  login: `${apiUrl}/login-web`,
  changeOfPassword: `${apiUrl}/change-password`,
};
