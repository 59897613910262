import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdiomasService {
  constructor(private httpClient: HttpClient) {}

  getIdiomaPorDefecto(): Observable<string> {
    return this.httpClient.get<string>(`${environment.apiUrl}/idioma`);
  }
}
