import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  EMAIL_REGEX,
  Nullable,
  ONLY_LETTERS_AND_NUMBERS,
  ONLY_NUMBER_REGEX,
  REGEX_ONLY_LETTER,
} from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class CustomSyncValidatorsService {
  emailRegexValidator(): ValidatorFn {
    return (control: AbstractControl): Nullable<ValidationErrors> => {
      if (!EMAIL_REGEX.test(control.value)) {
        return {
          emailRegex: true,
        };
      }
      return null;
    };
  }

  onlyAlphabeticValidator(options = { nullable: false }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (options.nullable && control.value === null) return null;
      if (!REGEX_ONLY_LETTER.test(control.value)) {
        return {
          alphabeticRegex: true,
        };
      }
      return null;
    };
  }

  noSpecialCharactersValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) return null;
      if (!ONLY_LETTERS_AND_NUMBERS.test(control.value)) {
        return {
          noSpecialCharacters: true,
        };
      }
      return null;
    };
  }

  onlyNumericValidator(options = { nullable: false }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (options.nullable && control.value === null) return null;
      if (!ONLY_NUMBER_REGEX.test(control.value)) {
        return {
          numberRegex: true,
        };
      }
      return null;
    };
  }

  passwordConfirmValidator(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        !Array.isArray(control.parent.controls) &&
        control.value === control.parent.controls[matchTo]?.value
        ? null
        : { valueOfControlsNotMatch: true };
    };
  }

  controlValueMustMatchWith(
    compareControl: AbstractControl,
    message = 'El valor de los campos debe coincidir.',
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const compareControlErrors = compareControl.errors;
      if (control.value !== compareControl.value) {
        const err = {
          valueOfControlsNotMatch: {
            message,
          },
        };

        if (control.dirty) {
          compareControl.setErrors(
            compareControlErrors ? { ...compareControlErrors, ...err } : err,
          );
        }

        return err;
      } else {
        if (compareControl.hasError('valueOfControlsNotMatch')) {
          const copyErrors = { ...compareControlErrors };
          delete copyErrors['valueOfControlsNotMatch'];
          compareControl.setErrors(
            Object.keys(copyErrors).length ? copyErrors : null,
          );
        }

        return null;
      }
    };
  }
}
