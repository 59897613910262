<div
  class="grid mb-3"
  [ngClass]="title ? 'justify-content-between' : 'justify-content-end'"
>
  <div *ngIf="title" class="flex col-12 xl:col">
    <button
      [routerLink]="goBackTo"
      *ngIf="goBackTo"
      class="mr-2"
      mat-icon-button
      type="button"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h1 class="inline-block">
      {{ title | capitalize }}
    </h1>
  </div>
  <div *ngIf="rightContent" class="col-12 xl:col-8">
    <ng-container *ngTemplateOutlet="rightContent"></ng-container>
  </div>
</div>
