/* eslint-disable @typescript-eslint/no-explicit-any */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ONLY_NUMBER_NOT_INITIAL_ZERO_REGEX = /^([1-9][0-9]{0,1})$/;
export const ONLY_NUMBER_REGEX = /^[0-9]*$/;

export const PH0NE_REGEX = /^[0-9]{8,13}$/;
export const DECIMAL_REGEX = /^\d*\.?\d{0,2}$/;

export const REGEX_ONLY_LETTER = /^(?!\s)([a-zA-ZÀ-ÿ\s]*)$/;

export const ONLY_LETTERS_AND_NUMBERS = /^[a-zA-ZÀ-ÿ\s0-9]*$/;

export const procesarImagen = (
  event: any,
): { image: string; size: number; nombre: string; tipo: string } | null => {
  const readerFile = new FileReader();

  if (event.target.files && event.target.files.length) {
    const file = event.target.files[0];

    readerFile.readAsDataURL(file);

    const tipo = file.type;
    const nombre = file.name.replace(/\.[^/.]+$/, '');

    readerFile.onload = () => {
      const strResult = readerFile.result as string;
      const size = file.size / 1024 / 1024;

      return { imagen: strResult, size, nombre, tipo };
    };
  }
  return null;
};

/**
 *
 * @param date string | Date
 * @returns string date format 'DD/MM/YYYY'
 */
export const parseDateToString = (
  date_value: any,
  format: 'DD-MM-YYYY' | 'YYYY-MM-DD' = 'YYYY-MM-DD',
) => {
  const date = new Date(date_value);
  let date_format = '';
  switch (format) {
    case 'DD-MM-YYYY':
      date_format = `${
        date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      }-${
        date.getUTCMonth() + 1 < 10
          ? '0' + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1
      }-${date.getUTCFullYear()}`;
      break;
    case 'YYYY-MM-DD':
      date_format =
        date.getUTCFullYear() +
        '-' +
        (date.getUTCMonth() + 1 < 10
          ? '0' + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1) +
        '-' +
        (date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate());
      break;
  }

  return date_format;
};
