import { createReducer, on } from '@ngrx/store';
import { Nullable } from '@shared/utils';
import * as actions from './auth.actions';
import { User } from '../models';

export const authFeatureKey = 'auth';

export interface State {
  updatingUser: boolean;
  loggingIn: boolean;
  user: Nullable<User>;
  token: Nullable<string>;
  error: unknown;
}

export const authInitialState: State = {
  updatingUser: false,
  loggingIn: false,
  user: null,
  token: null,
  error: null,
};

export const reducer = createReducer(
  authInitialState,

  // LOGIN

  on(actions.Login, (state) => ({
    ...state,
    loggingIn: true,
  })),
  on(actions.LoginSuccess, (state, { loginSuccess: { user, token } }) => ({
    ...state,
    loggingIn: false,
    user,
    error: null,
    token,
  })),
  on(actions.LoginFailure, (state, { error }) => ({
    ...state,
    error,
    loggingIn: false,
  })),

  // LOGOUT

  on(actions.Logout, (state) => state),
  on(actions.LogoutSuccess, () => authInitialState),
  on(actions.LogoutFailure, (state, { error }) => ({
    ...state,
    ...authInitialState,
    error,
  })),

  // FIRST LOGIN
  on(actions.SetNewPasswordForFirstLogin, (state) => state),
  on(actions.SetNewPasswordForFirstLoginSuccess, () => authInitialState),
  on(actions.SetNewPasswordForFirstLoginFailure, (state, { error }) => ({
    ...state,
    ...authInitialState,
    error,
  })),

  // UPDATE AUTH USER
  // on(actions.UpdateAuthUser, (state) => ({
  //   ...state,
  //   updatingUser: true,
  // })),
  // on(actions.UpdateAuthUserSuccess, (state, { update }) => ({
  //   ...state,
  //   user: state.user
  //     ? { ...state.user, person: { ...state.user.person, ...update } }
  //     : null,
  //   updatingUser: false,
  // })),
  // on(actions.UpdateAuthUserFailure, (state) => ({
  //   ...state,
  //   updatingUser: false,
  // })),
);
