import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Store } from '@ngrx/store';

import { AppState } from '@store';
import * as authStore from 'src/app/auth/store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public tokenRefresh = true;

  constructor(private store: Store<AppState>) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.store.select(authStore.selectToken).pipe(
      first(),
      mergeMap((token) => {
        if (!!token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next
          .handle(request)
          .pipe(catchError((err) => throwError(() => err)));
      }),
    );
  }
}
