import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastService } from '@core/services';
import * as actions from './auth.actions';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.Login),
      concatMap(({ payload }) =>
        this.authService.login(payload).pipe(
          map((data) => actions.LoginSuccess({ loginSuccess: data })),
          tap((r) => {
            if (r.loginSuccess.user.primer_inicio) {
              this.router.navigate(['/auth/request-password']);
            } else {
              this.router.navigate(['/dashboard']);
            }
          }),
          catchError((error) => {
            this.error.handleError(error);
            return of(actions.LoginFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.Logout),
      map(() => actions.LogoutSuccess()),
      tap(() => {
        this.router.navigate(['/auth/login']);
        this.toastService.showToast('Se ha cerrado la sesión', 'success');
      }),
    ),
  );

  public newPasswordAtFirstLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.SetNewPasswordForFirstLogin),
      concatMap(({ payload }) =>
        this.authService.newPassword(payload).pipe(
          map(() => actions.SetNewPasswordForFirstLoginSuccess()),
          tap(() => {
            this.router.navigate(['/auth/login']);
            this.toastService.showInfo(
              'Su nueva contraseña ha sido registrada, vuelva a iniciar sesión',
            );
          }),
          catchError((error) => {
            this.error.handleError(error);
            return of(actions.SetNewPasswordForFirstLoginFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private error: ErrorHandler,
    private router: Router,
    private toastService: ToastService,
  ) {}
}
