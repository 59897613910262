/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { Nullable } from '../../utils/types';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, takeUntil } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export interface FileInputChangeEvent {
  exceedsTheMaxSizeAllowed: boolean;
  originalEvent: Event;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: FileInputComponent },
  ],
})
export class FileInputComponent
  implements MatFormFieldControl<FileList>, OnDestroy, ControlValueAccessor
{
  private destroyed$ = new Subject<void>();
  public autofilled?: boolean | undefined;
  public controlType = 'file-input';
  public filesControl = new FormControl(null);
  public stateChanges = new Subject<void>();
  public userAriaDescribedBy?: string | undefined;

  // PLACEHOLDER
  @Input()
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder = '';

  // VALUE
  @Input()
  public get value(): Nullable<FileList> {
    return this.filesControl.value;
  }
  public set value(fileList: Nullable<FileList>) {
    this.filesControl.setValue(fileList);
    this.stateChanges.next();
  }

  public get empty(): boolean {
    return !this.filesControl.value;
  }

  // REQUIRED
  @Input()
  public get required(): boolean {
    return this._required;
  }
  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  public static nextId = 0;

  // DISABLED
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.filesControl.disable() : this.filesControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  // ERROR
  public get errorState(): boolean {
    return !!this.ngControl?.invalid && !!this.ngControl?.dirty;
  }

  @Input()
  public accept?: string;

  @Input()
  public showErrors = true;

  @Input()
  public multiple = false;

  @HostBinding()
  public id = `file-input-${FileInputComponent.nextId++}`;

  @HostBinding('class.floating')
  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('fileInput')
  public fileInputRef: ElementRef<HTMLInputElement> | null = null;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]): void {}

  writeValue(obj: FileList | null): void {
    this.value = obj || null;
  }
  registerOnChange(fn: any): void {
    this.filesControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.stateChanges.next();
        fn(this.fileInputRef?.nativeElement.files);
      });
  }
  registerOnTouched(fn: any): void {
    fn();
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.destroyed$.next();
  }

  public focused = false;
  public touched = false;

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  onFocusIn(event: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFileInputChange(ev: Event): void {
    this.value = (ev.target as HTMLInputElement)?.files || null;
  }

  onTouched(): void {}

  onFocusOut(event: FocusEvent): void {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }
}
