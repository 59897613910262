<mat-form-field
  [appearance]="fieldAppareance"
  [hintLabel]="hint"
  [ngClass]="fieldClassList"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      (blur)="onInputBlur()"
      (focus)="onFocusInput()"
      (keyup.enter)="onConfirm($event)"
      [formControl]="control"
      [type]="type"
      matInput
    />
  <button
    (click)="onCancel()"
    (mouseout)="isMouseOver = false"
    (mouseover)="isMouseOver = true"
    [disabled]="isLoading"
    *ngIf="isEditing"
    aria-label="Cancel"
    mat-icon-button
    matSuffix
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button
    (click)="onConfirm($event)"
    (mouseout)="isMouseOver = false"
    (mouseover)="isMouseOver = true"
    [disabled]="isLoading || control.invalid"
    *ngIf="isEditing"
    aria-label="Confirm"
    mat-icon-button
    matSuffix
    type="button"
  >
    <app-icon-or-spinner [spinnerIf]="isLoading">check</app-icon-or-spinner>
  </button>
  <mat-error *ngFor="let error of control.errors | keyvalue">{{ error | controlErrorMessage | translate: { error } }}</mat-error>
</mat-form-field>


